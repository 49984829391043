.schedule-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #f5f5f4;
}

.schedule-title {
  font-size: 56px;
  font-family: "Tangerine", serif;
  margin-bottom: 24px;
  margin-top: 24px;
}

.schedule-text {
  font-size: 42px;
  font-family: "Tangerine", serif;
  margin-bottom: 24px;
  margin-top: 24px;
}
