.App {
  text-align: center;
  background-color: #fff;
  width: 100%;
  height: 100vh;
}

.hidden {
  display: none;
}
