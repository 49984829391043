.footer-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.footer-text {
  font-size: 42px;
  font-family: "Tangerine", serif;
  margin-bottom: 24px;
  margin-top: 24px;
}
