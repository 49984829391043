.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
}

.guest-form {
  display: flex;
  flex-direction: column;
  border: 2px solid #e5e7eb;
  background-color: #fff;
  padding: 25px;
  border-radius: 10px;
  margin-bottom: 25px;
  width: 100%;
}

.form-label {
  text-align: left;
}

.form-info {
  font-size: 12px;
  font-style: italic;
}

.form-checkbox {
  display: flex;
  justify-content: space-between;
}

.form-input-group {
  display: flex;
  flex-direction: column;
}

.input-container {
  margin-bottom: 12px;
}

.guest-name {
  font-size: 42px;
  font-family: "Tangerine", serif;
  margin-bottom: 24px;
}
