.landing-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-image: url("bg.png");
  background-color: #f8fafc;
  background-size: 100% 100%;
  width: 100vw;
}

.names {
  font-size: 72px;
  font-family: "Tangerine", serif;
}

.date {
  font-size: 16px;
  margin-top: 25px;
}

.arrow {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 25px;
  font-size: 20px;
  color: white;
  font-weight: 600;
}

.arrow-down {
  align-self: flex-end;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  height: 40px;
  margin: 0 auto 0 auto;
  transform-origin: bottom;
  width: 40px;
  animation-name: bounce-2;
  animation-timing-function: ease;
}

@keyframes bounce-2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0);
  }
}
