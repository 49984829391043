.form-page {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 14px;
  flex-direction: column;
  margin-bottom: 25px;
}

.add-guest-btn {
  width: 200px;
  color: #374151;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 25px;
}

.form-submit {
  border: none;
  width: 200px;
  height: 50px;
  border-radius: 5px;
  background-color: #14532d;
  color: white;
  font-size: 16px;
  display: inline;
  cursor: pointer;
}

.form-page-text {
  font-size: 42px;
  font-family: "Tangerine", serif;
  margin-bottom: 24px;
}
.form-page-text-description {
  font-size: 32px;
  font-family: "Tangerine", serif;
  margin-bottom: 24px;
}
