.place-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  padding-top: 25px;
  background-color: #f5f5f4;
}

.map {
  width: 100%;
}
